<template>
  <component :is="wrapperComponent">
    <div
      class="row csn-user-account csn-user-account-section"
      id="user-account"
    >
      <div class="col-md-12">
        <div class="container">
          <WithdrawalPanel />
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  WRAPPER,
  USER,
  IS_MOBILE_SCREEN,
  Module,
  WITHDRAWAL_ADD,
} from '@/constants'
import { dest } from '@/helpers'

export default {
  name: WITHDRAWAL_ADD,
  components: {
    WithdrawalPanel: () => import('@/components/WithdrawalPanel'),
    User: () => import('@/views/User'),
    Wrapper: () => import('@/components/Wrapper'),
  },
  computed: {
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    wrapperComponent() {
      return this.isMobileScreen ? WRAPPER : USER
    },
  },
}
</script>
